import React, { Children } from "react";
import { Link } from "gatsby";

import styles from "./button.module.scss";
import cx from "classnames";

export default ({ children, type, size, to }) => {
  let options = {
    [styles.outline]: type == "outline",
    [styles.inverted]: type == "inverted",
    [styles.alt]: type == "alt",
    [styles.medium]: size == undefined || size == "m",
    [styles.large]: size == "l",
    [styles.small]: size == "s",
  };

  const style = cx(styles.button, options);

  return (
    <Link to={to} className={style}>
      <span className={styles.text}>{children}</span>
    </Link>
  );
};
