import React from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import styles from "./layout.module.scss";
import { AppProvider } from "@shopify/polaris";

import "@shopify/polaris/dist/styles.css";

const Container = ({ children }) => (
  <div id="outer-container" className={styles.container}>
    {children}
  </div>
);

class Template extends React.Component {
  render() {
    const { location, children } = this.props;

    let rootPath = `/`;
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`;
    }

    return (
      <AppProvider>
        <Container>
          <Navigation />
          <div id="content" className={styles.content}>
            {children}
            {/* <Footer>SEO links</Footer> */}
          </div>
        </Container>
      </AppProvider>
    );
  }
}

export default Template;
