import React, { useState, useCallback } from "react";
import { Link, navigate } from "gatsby";
import Menu from "./menu";
import Button from "../system/button";

import styles from "./navigation.module.scss";
import Logo from "../system/logo";

import classNames from "classnames/bind";
let cx = classNames.bind(styles);

let navItemVisible = cx({
  item: true,
  visible: true,
});

class Navigation extends React.Component {
  toggleMenu() {
    this.childMenu.open();
  }

  render() {
    // console.log("log: navigation");
    return (
      <nav role="navigation" className={styles.header}>
        <div className={styles.inner}>
          <Link to="/" className={styles.logo}>
            <Logo theme="white" />
            <span>Wellington Community Carers</span>
          </Link>
          <ul className={styles.navigation}>
            <li>
              <Link to="/carers/">Our carers</Link>
            </li>
            <li>
              <Link to="/services/">Services offered</Link>
            </li>
            <Button type="inverted" to="/contact/">
              Contact us
            </Button>
          </ul>
          <a className={styles.burger} onClick={() => this.toggleMenu()}>
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2C24 3.10457 23.1046 4 22 4H2C0.89543 4 0 3.10457 0 2ZM0 9C0 7.89543 0.895431 7 2 7H22C23.1046 7 24 7.89543 24 9C24 10.1046 23.1046 11 22 11H2C0.89543 11 0 10.1046 0 9ZM2 14C0.895431 14 0 14.8954 0 16C0 17.1046 0.89543 18 2 18H22C23.1046 18 24 17.1046 24 16C24 14.8954 23.1046 14 22 14H2Z"
              />
            </svg>
          </a>
        </div>

        <Menu
          className={styles.mobileNavStyles}
          ref={(el) => (this.childMenu = el)}
        >
          <Link to="/">
            <Logo className={styles.icon} theme="white" />
          </Link>
          <ul className={styles.mobileItems}>
            <Link to="/carers/">
              <li className={styles.mobileItem}>Our carers</li>
            </Link>
            <Link to="/services/">
              <li className={styles.mobileItem}>Services offered</li>
            </Link>
            {/* <Link to="/join/">
              <li className={styles.mobileItem}>Join the network</li>
            </Link> */}
          </ul>
          <Button type="inverted" to="/contact/">
            Contact us
          </Button>
        </Menu>
      </nav>
    );
  }
}

export default Navigation;
