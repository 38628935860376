import React from "react";

import styles from "./logo.module.scss";
import cx from "classnames";

export default ({ size, theme }) => {
  let height, logoStyle;

  if (size) {
  } else {
    height = styles.medium;
  }

  if (theme) {
    logoStyle = styles[theme];
  } else {
    logoStyle = styles.logo;
  }

  return (
    <svg
      className={cx(logoStyle, height)}
      fill="none"
      width="111"
      height="97"
      viewBox="0 0 111 97"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        className={styles.outer}
        d="M48.8877 1.56505C51.2148 -0.300431 54.4577 -0.518242 57.0133 1.01931L109.094 32.3533C112.352 34.3132 111.145 39.3026 107.352 39.5573L101.394 39.9575L92.7963 90.6864C92.3741 93.1773 90.2163 95.0001 87.6899 95.0001L23.2925 94.0001C20.6835 94.0001 18.4819 92.0595 18.1543 89.4713L12.5157 45.9269L4.1548 46.4885C0.36148 46.7433 -1.50148 41.9601 1.46488 39.5821L48.8877 1.56505ZM51.9963 38.4368C50.7477 36.5259 48.0434 34.3108 43.0536 34.0436C34.6192 33.5919 24.6681 45.2096 32.2006 56.4276C34.0546 59.1887 35.5618 60.311 38.1689 62.2521L38.169 62.2522C40.4165 63.9257 43.4815 66.2078 48.2908 70.6732C50.6073 72.824 51.5543 75.9052 51.1752 79.7408C51.2571 79.6409 51.3455 79.5277 51.4384 79.4021C51.1206 80.8946 50.8972 82.4882 50.7975 84.1834C52.915 81.0777 53.8537 79.1476 54.6717 77.4656C55.9166 74.9059 56.882 72.921 61.2974 68.2423C62.9365 66.5055 65.2095 64.6999 67.6232 62.7826C74.7446 57.1258 83.0898 50.4967 79.9878 41.7925C75.9607 30.4928 66.0383 29.8306 60.1774 31.4239C57.3844 32.1833 54.0463 35.0103 51.9963 38.4368Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        className={styles.inner}
        d="M43.0536 34.0436C48.0434 34.3108 50.7477 36.5259 51.9963 38.4368C54.0463 35.0103 57.3844 32.1832 60.1774 31.4239C66.0383 29.8306 75.9607 30.4928 79.9878 41.7925C83.0898 50.4967 74.7446 57.1258 67.6232 62.7826C65.2096 64.6999 62.9365 66.5055 61.2974 68.2423C56.882 72.921 55.9166 74.9059 54.6717 77.4656C53.8537 79.1476 52.915 81.0777 50.7975 84.1834C50.8972 82.4882 51.1206 80.8946 51.4384 79.4021C51.3455 79.5277 51.2571 79.6409 51.1752 79.7408C51.5543 75.9052 50.6073 72.824 48.2908 70.6732C43.4815 66.2077 40.4165 63.9256 38.1689 62.2521L38.1689 62.2521C35.5618 60.311 34.0546 59.1887 32.2006 56.4276C24.6681 45.2096 34.6192 33.592 43.0536 34.0436Z"
      />
    </svg>
  );
};
